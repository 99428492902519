.acomodo {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.lpn {
    display: grid;
    grid-template-columns: 1fr 200px;
    /* flex-direction: column; */
    gap: 1rem;
}

.panel {
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.panel form {
    width: 50%;
}

.detail {
    display: flex;
    width: 100%;
    gap: 1rem;
    align-items: flex-end;
    justify-content: space-between;
}

.product {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product img {
    width: 70%;
}

.estatus {
    display: flex;
    gap: 1rem;
    align-items: ce;
}


.subtitle {
    font-size: 18px;
    color: #5c5c5c;
    font-weight: 600;   
}

.rowrep {
    display: grid;
    padding: 1rem 0;;
    grid-template-columns: 1fr 1fr 3fr 1fr 1fr 1fr 1fr 4fr;
    align-items: center;
    font-size: 12px;
}