.calculator {
    padding: 1rem;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.rowbutton {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.buttons .digit {
    width: 64px;
    height: 64px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 32px;
    line-height: 64px;
    text-align: center;
}

form.formCalculator input {
    outline: none;
    border: none;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    line-height: 24px;
    padding: .75rem 0;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
} 

.skelton {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}