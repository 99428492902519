.parameters,
.actions {
    display: flex;
    justify-content: space-around;
    padding: 0.5rem 0;
}

.row2 {
    font-size: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.row3 {
    font-size: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.row4 {
    font-size: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 3fr 1fr 1fr 1fr 1fr;
}

 .th {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
}

.td {
    font-size: 10px;
}

.action {
    display: flex;
    width: 100%;
    padding: 1rem 0;
    justify-content: center;
}

.action button {
    background-color: cadetblue;
    color: #FFF;
    font-weight: 600;
    border: none;
    outline: none;
    padding: 0.25rem 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.action button:hover {
    background-color: gray;
    transition: all 0.3s ease-in-out;
}

.corte {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 1rem 0;
}

.corte button {
    background-color: cadetblue;
    color: #FFF;
    border: 1px solid cadetblue;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    outline: none;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.success {
    font-size: 1.1rem;
    color: greenyellow;
    font-weight: 600;
}

.error {
    font-size: 1.1rem;
    color: red;
    font-weight: 600;
}

.filtros form {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.impresiones {
    margin-top: 1rem;
}