.areas {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.ordenes {
    display: flex;
    gap: 1rem;
}


.ordenesTable {
    width: 100%;
    
}


.ordenesPanel {
    width: 400px;
    display: flex;
    flex-direction: column;
}

.ordenesPanel .panel {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}


.actions {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    gap: 0 1rem;
}

.headerDialogPrinter {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.reload {
    padding: 0.5rem 0;
    display: flex;
    justify-content: flex-end;
}