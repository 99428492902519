.picking {
    display: flex;
    gap: 1rem;
    padding: 1rem; 
    margin: 0 auto;
}

.orgs {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.orgs .orgs-img {
    width: 100%;
    position: relative;
}

.orgs-img img {
    width: 100%;
    height: 60vh;
}

.orgs select {
    padding: 0.25rem 0.5rem;
}

.orgs-img .print-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.products .row {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr 1fr 1fr;
}

.actived {
    background-color: blueviolet;
}