a {
    text-decoration: none;
    color: #7c7c7c;
}

.active {
    color: #1976d2;
    font-weight: 500;
}

.child {
    padding: 1rem;
}


.navbar {
    display: flex;
    padding: 0 1rem;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.brand img {
    height: 32px;
}

ul.navlink {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2rem;
    list-style-type: none;
}

.navlink > li > a{
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.avatar {
    display: flex;
    gap: 0.3rem;
    height: 64px;
    font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    align-items: center;
}

.avatar .avatar-body {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: center;
}

.avatar-body span:first-child {
    font-size: 14px;
    color: #5c5c5c;
    font-weight: 500;
    text-transform: uppercase;
}

.avatar-body span:last-child {
    font-size: 12px;
    color: #5c5c5c;
    text-transform: capitalize;
    font-weight: 400;
}
